*{
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


#sidebar-wrapper{
  background-color: #DAE3F3;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
}

#sidebar ul{
  list-style-type: circle;
}

#mobile-bar{
  text-align: center;
  background-color: #DAE3F3;
  padding: 5px;
  display: none;
}

#content-wrapper{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  /* min-width: 425px; */
}

#mobile-profile-img{
  max-width: 120px;
  transform: rotate(20deg); 
  display: none;
}

#banner{
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  min-height: 200px;
}

#banner-title{
  font-size: 40px;
  width: 100%;
  text-align: center;
}

.center-button{
  margin: 5px;
}

.section-title{
  background-color: #DAE3F3;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

.skillItem{
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.skillItem:hover {
  background-color: #F5F5F5;
  
}

.skillItem.active{
  background-color: #B4C7E7;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.jumbotron {
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

#center-button-wrapper{
  display: flex;
  justify-content: center;
}

.project-title{
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin: 0.67em 0;
}
.tech-used-title{
  font-size: 1.25rem;
  font-weight: 300;
}
.tech-used{
  background-color: #C8C8C8;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
  height: 40px;
}


@media (max-width: 768px) {
  #sidebar-wrapper{
    display: none;
  }

  #content-wrapper{
    height: 100%;
  }

  #mobile-profile-img{
    display: block;
  }

  #mobile-bar{
    display: block;
  }
}

@media ( max-width: 576px ) {
  .container-fluid{
    padding-left: 2px;
    padding-right: 2px;
  }

  .jumbotron {
    padding: 1rem;
  }
  #banner-title{
    font-size: 25px;
  }
  .project-title{
    font-size: 2.5rem;
  }
  
  #center-button-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .center-button{
    /* width: 45%; */
    float: left;
    font-size: 15px;
  }

  .center-button-full{
    /* width: 90%; */
  }
}